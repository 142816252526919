<template>
  <section>
    <b-card no-body>
      <b-tabs
        class="mb-0 pt-0 pb-0 head"
        card
        justified
      >
        <b-tab
          v-for="(day, index) in daysOfWeek"
          :key="index"
          :title="day"
          class="p-0 m-0"
        >
          <b-row>
            <b-col
              v-for="(option, hourIndex) in hourlyArray"
              :key="hourIndex"
              xl="3"
              md="3"
              sm="6"
              class="mt-1"
            >
              <ValidationProvider
                v-slot="{ errors }"
                :name="`Impressions for ${option}`"
                :vid="`hivestack-imp-${index}-${hourIndex}`"
                :rules="selectedHours[index][hourIndex] ? 'required' : ''"
              >
                <b-input-group
                  class="input-group-merge"
                  size="sm"
                >
                  <!-- size="sm" -->
                  <b-input-group-prepend is-text>
                    <b-form-checkbox
                      :id="`operating-hours-${index}-${hourIndex}`"
                      v-model="selectedHours[index][hourIndex]"
                      size="sm"
                      plain
                      :value="true"
                      :unchecked-value="false"
                      @change="handleCheckboxChange(index, hourIndex, selectedHours[index][hourIndex])"
                    />
                    <b-tooltip
                      variant="secondary"
                      title="Operating Hours"
                      trigger="hover"
                      :target="`operating-hours-${index}-${hourIndex}`"
                    />
                  </b-input-group-prepend>
                  <!-- :input-id="`hivestack-imp-${index}-${hourIndex}`" -->
                  <b-form-input
                    :id="`hivestack-imp-${index}-${hourIndex}-id`"
                    v-model="inputValues[index][hourIndex]"
                    type="number"
                    title=""
                    :vid="`hivestack-imp-${index}-${hourIndex}`"
                    :disabled="!selectedHours[index][hourIndex]"
                    placeholder="Enter Imp"
                    :state="errors.length > 0 ? false:null"
                    @wheel="onWheel"
                    @change="handleInputChange(index, hourIndex)"
                  />
                  <b-tooltip
                    variant="secondary"
                    trigger="hover"
                    :title="`Impressions per hour (${option})`"
                    :target="`hivestack-imp-${index}-${hourIndex}-id`"
                  />
                  <b-input-group-append>
                    <b-input-group-text>
                      {{ option }}
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </section>
</template>
<script>
import {
  BCard, BTabs, BTab, BFormCheckbox, BInputGroup, BFormInput,
  BRow, BCol, BInputGroupText, BInputGroupAppend, BInputGroupPrepend, BTooltip,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
// import { required } from '@validations'
import { required } from 'vee-validate/dist/rules'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BFormCheckbox,
    ValidationProvider,
    BInputGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroupText,
    BInputGroupPrepend,
    BInputGroupAppend,
    BTooltip,
  },
  props: {
    operatingHours: {
      type: String,
      required: true,
    },
    impressions: {
      type: String,
      required: true,
    },
    updateImp: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      localOperatingHours: this.operatingHours,
      localImpressions: this.impressions,
    }
  },
  computed: {
    daysOfWeek() {
      return ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    },
    /* eslint-disable */
    hourlyArray() {
      const hourArray = []
      for (let i = 0; i <= 23; i++) {
        const formattedHour = i.toString().padStart(2, '0')
        hourArray.push(`${formattedHour}:00`)
      }
      return hourArray
    },
    selectedHours() {
      const selectedHours = []
      for (let i = 0; i < 7; i++) {
        const startIndex = i * 24
        const endIndex = startIndex + 23
        const dayHours = this.localOperatingHours
          ? this.localOperatingHours.substring(startIndex, endIndex + 1)
          : ''
        selectedHours.push(dayHours.split('').map(bit => bit === '1'))
      }
      return selectedHours
    },
    inputValues() {
         // Parse the localImpressions string and generate inputValues
      const impressionsArray = this.localImpressions.split(',')
      const inputValues = []
      let impressionIndex = 0
      for (let i = 0; i < 7; i++) {
        const inputArray = []
        for (let j = 0; j < 24; j++) {
          if (this.selectedHours[i][j]) {
            // Check if operatingHour is true, if true, use the actual value
            inputArray.push(impressionsArray[impressionIndex])
          } else {
            // If operatingHour is false, assign 0
            inputArray.push('0')
          }
          impressionIndex++
        }
        inputValues.push(inputArray)
      }
      return inputValues
    },
    /* eslint-enable */
  },
  created() {
    // Call necessary methods to initialize component state
    this.updateImpressions()
    // Watch for changes in selectedHours and update localImpressions accordingly
    this.$watch('selectedHours', () => {
      this.updateImpressions()
    }, { deep: true }) // Use deep: true to watch nested changes within selectedHours
  },
  methods: {
    onWheel(e) {
      e.target.blur() // This will remove focus from the input element when the wheel event occurs
    },
    handleCheckboxChange(dayIndex, hourIndex, newValue) {
      if (newValue === this.selectedHours[dayIndex][hourIndex]) {
        if (!newValue) {
          this.inputValues[dayIndex][hourIndex] = ''
        }
      }
      // Update localOperatingHours here
      this.localOperatingHours = this.convertSelectedHoursToString(this.selectedHours)
      // Emit an event to notify the parent of the updated operatingHours
      this.$emit('operating-hours-updated', this.localOperatingHours)
    },
    // Function to convert the selectedHours array to a string
    convertSelectedHoursToString(selectedHours) {
      return selectedHours.map(dayHours => dayHours.map(selected => (selected ? '1' : '0')).join('')).join('')
    },
    /* eslint-disable */
    handleInputChange(dayIndex, hourIndex) {
      if (!this.selectedHours[dayIndex][hourIndex]) {
        // If operatingHour is false, set the input value to 0
        this.inputValues[dayIndex][hourIndex] = '0'
      }
      this.$emit('hivestack-imp-updated', true)
      // Update localImpressions here
      this.updateImpressions()
    },
    /* eslint-enable */
    updateImpressions() {
      /* eslint-disable */
      // Convert the inputValues to a comma-separated string
      const impressionsArray = []
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 24; j++) {
          impressionsArray.push(this.inputValues[i][j])
        }
      }
      this.localImpressions = impressionsArray.join(',')
      // Emit an event to notify the parent of the updated impressions
      this.$emit('impressions-updated', this.localImpressions)
    },
  },
}
</script>

<style scoped lang="scss">
.heads {
  .card-header {
    padding: 0px !important;
  }
}
</style>
